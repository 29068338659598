import React from 'react';
import {Link} from 'gatsby';

import './header.scss';

function Header() {
  return (
    <div className="Header">
      <div className="left">
        <Link to="/">social impact media club</Link>
      </div>
      <div className="right">
        <Link to="/about">about</Link>
      </div>
    </div>
  );
}

export default Header;
