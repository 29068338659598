import {StaticQuery, graphql} from 'gatsby';
import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';
import React from 'react';

import 'layouts/index.scss';

const TemplateWrapper = ({children}) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={() => (
      <div>
        <Helmet>
          <title>social impact media club</title>
          <meta name="description" content="andrew klingelhofer" />
          <meta name="keywords" content="andrew klingelhofer" />
          <link
            href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700;900&family=Roboto:wght@100;300&display=swap"
            rel="stylesheet"
          ></link>
        </Helmet>
        <div>{children}</div>
      </div>
    )}
  />
);

TemplateWrapper.propTypes = {
  children: PropTypes.any
};

export default TemplateWrapper;
